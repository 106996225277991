import kaplay from "kaplay";
import { crossFadeGo, loadShaders } from "./shaders";
import fusionPixel from './assets/fonts/fusion-pixel-10px-monospaced-zh_hans.ttf'
import fusionPixelDev from '../scripts/fusion-pixel-10px-monospaced-zh_hans.ttf'

const fontUrl = import.meta.env.DEV ? fusionPixelDev : fusionPixel

const k = kaplay({
  background: [238, 144, 203],
  font: "FusionPixel",
  crisp: true
});

k.loadFont("FusionPixel", fontUrl)

loadShaders(k)

k.loadSprite("bag", "/sprites/bag.png");
k.loadSprite("ghosty", "/sprites/ghosty.png");
k.loadSprite("grass", "/sprites/grass.png");
k.loadSprite("steel", "/sprites/steel.png");
k.loadSprite("door", "/sprites/door.png");
k.loadSprite("key", "/sprites/key.png");
k.loadSprite("bean", "/sprites/bean.png");

k.scene("main", (levelIdx) => {
  const SPEED = 256;

  // character dialog data
  const characters = {
    "a": {
      sprite: "bag",
      msg: "Hi Y! 欢迎来到魔法的世界，你的爱人留下了一封信给你，找到🔑并打开🚪拿到信吧！",
    },
    "b": {
      sprite: "ghosty",
      msg: "需要回答问题我才能让你过",
    },
  };

  // level layouts
  const levels = [
    [
      "===========",
      "=  b     =",
      "=   =    =",
      "=    =   =",
      "=  ===   =",
      "=  =     =",
      "=  = $=  =",
      "=  ===  ==",
      "=        =",
      "=  =     =",
      "=  =     =",
      "=  =     =",
      "=  =     =",
      "=a =     |",
      "= @===   =",
      "==========="
    ],
  ];

  const level = k.addLevel(levels[levelIdx], {
    tileWidth: 64,
    tileHeight: 64,
    pos: k.vec2(38, 38),
    tiles: {
      "=": () => [
        k.sprite("grass"),
        k.area(),
        k.body({ isStatic: true }),
        k.anchor("center"),
      ],
      "-": () => [
        k.sprite("steel"),
        k.area(),
        k.body({ isStatic: true }),
        k.anchor("center"),
      ],
      "$": () => [
        k.sprite("key"),
        k.area(),
        k.anchor("center"),
        "key",
      ],
      "@": () => [
        k.sprite("bean"),
        k.area(),
        k.body(),
        k.anchor("center"),
        k.scale(0.95),
        "player",
      ],
      "|": () => [
        k.sprite("door"),
        k.area(),
        k.body({ isStatic: true }),
        k.anchor("center"),
        "door",
      ],
    },
    // any() is a special function that gets called everytime there's a
    // symbole not defined above and is supposed to return what that symbol
    // means
    wildcardTile(ch) {
      const char = characters[ch];
      if (char) {
        return [
          k.sprite(char.sprite),
          k.area(),
          k.body({ isStatic: true }),
          k.anchor("center"),
          "character",
          { msg: char.msg },
        ];
      }
    },
  });

  // get the player game obj by tag
  const player = level.get("player")[0];

  function addDialog() {
    const h = 256;
    const pad = 16;
    const bg = k.add([
      k.pos(0, k.height() - h),
      k.rect(k.width(), h),
      k.color(0, 0, 0),
      k.z(100),
    ]);
    const txt = k.add([
      k.text("", {
        width: k.width() - pad,
        lineSpacing: 8,
      }),
      k.pos(0 + pad, k.height() - h + pad),
      k.z(100),
    ]);
    bg.hidden = true;
    txt.hidden = true;
    return {
      say(t) {
        txt.text = t;
        bg.hidden = false;
        txt.hidden = false;
      },
      dismiss() {
        if (!this.active()) {
          return;
        }
        txt.text = "";
        bg.hidden = true;
        txt.hidden = true;
      },
      active() {
        return !bg.hidden;
      },
      destroy() {
        bg.destroy();
        txt.destroy();
      },
    };
  }

  let hasKey = false;
  const dialog = addDialog();

  player.onCollide("key", (key) => {
    k.destroy(key);
    hasKey = true;
  });

  player.onCollide("door", () => {
    if (hasKey) {
      if (levelIdx + 1 < levels.length) {
        crossFadeGo(k, "main", levelIdx + 1)
      }
      else {
        crossFadeGo(k, "win")
      }
    }
    else {
      dialog.say("you got no key!");
    }
  });

  let dir: (keyof typeof dirs | null) = null;

  // talk on touch
  player.onCollide("character", (ch) => {
    dialog.say(ch.msg);
    dir = null
  });

  const dirs = {
    "left": k.LEFT,
    "right": k.RIGHT,
    "up": k.UP,
    "down": k.DOWN,
  };

  for (const dir in dirs) {
    k.onKeyPress(dir, () => {
      dialog.dismiss();
    });
    k.onKeyDown(dir, () => {
      player.move(dirs[dir].scale(SPEED));
    });
  }
  const btnsPos = {
    up: k.pos(k.width() / 2, k.height() - 256),
    down: k.pos(k.width() / 2, k.height() - 156),
    left: k.pos(k.width() / 2 - 128, k.height() - 156),
    right: k.pos(k.width() / 2 + 128, k.height() - 156),
  }
  const up = k.add([
    k.rect(80, 80, { radius: 8 }),
    btnsPos.up,
    k.area(),
    k.scale(1),
    k.anchor("center"),
    k.outline(4),
    k.color(255, 255, 255),
  ]);
  up.add([
    k.text('上'),
    k.anchor("center"),
    k.color(0, 0, 0),
  ]);

  const down = k.add([
    k.rect(80, 80, { radius: 8 }),
    btnsPos.down,
    k.area(),
    k.scale(1),
    k.anchor("center"),
    k.outline(4),
    k.color(255, 255, 255),
  ]);
  down.add([
    k.text('下'),
    k.anchor("center"),
    k.color(0, 0, 0),
  ]);

  const left = k.add([
    k.rect(80, 80, { radius: 8 }),
    btnsPos.left,
    k.area(),
    k.scale(1),
    k.anchor("center"),
    k.outline(4),
    k.color(255, 255, 255),
  ]);
  left.add([
    k.text('左'),
    k.anchor("center"),
    k.color(0, 0, 0),
  ]);

  const right = k.add([
    k.rect(80, 80, { radius: 8 }),
    btnsPos.right,
    k.area(),
    k.scale(1),
    k.anchor("center"),
    k.outline(4),
    k.color(255, 255, 255),
  ]);
  right.add([
    k.text('右'),
    k.anchor("center"),
    k.color(0, 0, 0),
  ]);
  k.onTouchStart(() => {
    dialog.dismiss()
    const DIST = 24
    const mousePos = k.mousePos()
    if (btnsPos.up.pos.dist(mousePos) < DIST) {
      dir = 'up'
    } else if (btnsPos.down.pos.dist(mousePos) < DIST) {
      dir = 'down'
    } else if (btnsPos.left.pos.dist(mousePos) < DIST) {
      dir = 'left'
    } else if (btnsPos.right.pos.dist(mousePos) < DIST) {
      dir = 'right'
    } else {
      dir = null
    }
  })
  k.onTouchEnd(() => {
    console.log('touch end', k.mousePos())
    dir = null
  })
  k.onUpdate(() => {
    if (dir && dirs[dir]) {
      player.move(dirs[dir].scale(SPEED));
    }
  })
});


k.scene("win", () => {

  const text = k.add([
    k.text("亲爱的Y：\n\n当你找到这封信的时候我已经似了，我们已经相处了一辈子，与你相处的每一刻时光都十分美好，我感到非常幸福，人生已经没有缺憾了。\n\n你永远都那么活泼动人，你是我黯淡人生里仅有的一抹光，世界正在分崩离析，我也已经离开这个世界，我永远怀念与你相处的日子，我永远爱你，再见了，我在来世等你。\n\n20xx年1月1日", {
      width: k.width() / 1.3,
      letterSpacing: 2,
      lineSpacing: 8,
      transform: (idx, ch) => {
        return {
          // opacity: idx < text.letterCount ? 1 : 0,
        };
      },
    }),
    k.pos(k.width() / 2, k.height() / 2 - 128),
    k.anchor("center"),
    k.z(100),
    k.color(50, 50, 50),
    {
      letterCount: 0,
    },
  ]);
  const back = k.add([
    k.rect(128, 80, { radius: 8 }),
    k.pos(k.width() / 2, k.height() - 156),
    k.area(),
    k.scale(1),
    k.anchor("center"),
    k.outline(4),
    k.color(255, 255, 255),
  ]);
  back.add([
    k.text('重来'),
    k.anchor("center"),
    k.color(0, 0, 0),
  ]);
  back.onClick(() => {
    crossFadeGo(k, 'menu')
  })
});

k.scene("menu", () => {
  k.add([
    k.sprite('bean'),
    k.pos(k.width() / 2, k.height() / 2),
    k.scale(4),
    k.anchor('center')
  ])

  k.add([
    k.text("Y!", {
      letterSpacing: 1,
      size: 128,
      transform: (idx) => ({
        pos: k.vec2(0, k.wave(-4, 4, k.time() * 4 + idx * 0.5)),
        scale: k.wave(1, 1.2, k.time() * 3 + idx),
      })
    }),
    k.pos(k.width() / 2),
    k.anchor('center'),

  ])

  k.add([
    k.text("点击开始", {
      letterSpacing: 1,
      size: 48,
      transform: () => ({
        opacity: k.wave(0, 1, k.time() * 3)
      })
    }),
    k.pos(k.width() / 2, k.height() - 256),
    k.anchor('center'),
  ])

  k.onClick(() => {
    crossFadeGo(k, 'win')
  })
})

k.go("menu", 0);
